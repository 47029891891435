import axios from "axios";
import _ from "lodash";
import * as Sentry from "@sentry/react";

window._ = _;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

(window as any).axios = axios;

(window as any).axios.defaults.headers.common["X-Requested-With"] =
    "XMLHttpRequest";

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from "laravel-echo";

import Pusher from "pusher-js";
(window as any).Pusher = Pusher;

(window as any).Echo = new Echo({
    broadcaster: "reverb",
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT,
    wssPort: import.meta.env.VITE_REVERB_PORT,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? "https") === "https",
    enabledTransports: ["ws", "wss"],
});

/**
 * Sentry allows to track user errors in an easy way
 */
window.a = import.meta.env.VITE_SENTRY_DSN;
if (!!import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,

        integrations: [
            Sentry.extraErrorDataIntegration(),
            Sentry.browserTracingIntegration(),
            Sentry.browserProfilingIntegration(),
            Sentry.replayIntegration(),
            Sentry.contextLinesIntegration(),
            Sentry.httpClientIntegration(),
            Sentry.reportingObserverIntegration(),
            Sentry.sessionTimingIntegration(),
            Sentry.feedbackIntegration(),
        ],

        release: import.meta.env.VITE_SENTRY_RELEASE,
        environment: import.meta.env.MODE,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,

        // Set profilesSampleRate to 1.0 to profile every transaction.
        // Since profilesSampleRate is relative to tracesSampleRate,
        // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
        // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
        // result in 25% of transactions being profiled (0.5*0.5=0.25)
        profilesSampleRate: 1.0,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,

        // This option is required for capturing headers and cookies.
        sendDefaultPii: true,

        // Shor report modal before sending the errors to Sentry
        beforeSend(event, hint) {
            // Check if it is an exception, and if so, show the report dialog
            if (event.exception && event.event_id) {
                Sentry.showReportDialog({ eventId: event.event_id });
            }

            return event;
        },
    });
}

/**
 * Chart.js allows us to plot charts in an easy way.
 */
import {
    CategoryScale,
    Chart,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Tooltip,
    Legend,
} from "chart.js";

Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Tooltip,
    Legend
);
